.person {
	padding: 0 4px;
	position: relative;
	z-index: 0;

	&.disabled {
		.person__image {
			filter: grayscale(1);
			opacity: .5;
		}

		.person__name {
			color: #aaa;
			text-decoration: line-through;
		}
	}
}

.person__image {
	border-radius: 12px;
	object-fit: cover;
	vertical-align: middle;

	@media only screen and (max-height: 600px) {
		height: 55vh;
		width: 40vh;
	}
}

.person__name {
	bottom: 0;
	color: #fff;
	left: 0;
	margin: 0;
	padding: 16px 8px;
	position: absolute;
	text-align: center;
	text-shadow: 0 2px 4px rgba(0, 0, 0, .6);
	width: 100%;
	z-index: 1;

	@media only screen and (max-height: 600px) {
		font-size: 10px;
	}
}

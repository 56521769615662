:root {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
}

*,
*::after,
*::before {
	box-sizing: inherit;
}

.App {
	align-items: center;
	background: #121820;
	display: flex;
	flex-direction: column;
	font-family: sans-serif;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	text-align: center;
}

.settings {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-height: 100%;
	padding: 32px;

	&__heading {
		color: #fff;
	}

	&__people-container {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		justify-content: center;
		overflow: auto;
		width: 100%;
	}

	.person {
		margin-bottom: 8px;

		&__image {
			cursor: pointer;
			height: 200px;
			transition: .15s;
			width: 150px;

			&:hover {
				opacity: .9;
			}
		}

		&__name {
			font-size: 14px;
			pointer-events: none;
		}
	}
}

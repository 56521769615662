.reset-button {
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	position: fixed;
	right: 24px;
	top: 24px;
	transition: opacity .2s;
	z-index: 99;

	&.loaded {
		opacity: 1;
	}

	svg {
		height: 40px;
		width: 40px;
	}

	path {
		fill: #ccc;
	}

	&:hover {
		path {
			fill: #04d342;
		}
	}
}

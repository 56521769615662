@function rand-left($seed) {
	$seed: ($seed * 9301 + 49297) % 233280 !global;
	@return (($seed / 233280) * 60);
}

@function rand-top($seed) {
	$seed: ($seed * 9482 + 51462) % 345615 !global;
	@return (($seed / 345615) * 60);
}

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@900&display=swap');

.bonanza {
	.winner {
		&:not(.revealed) {
			opacity: 0;
		}

		&::before {
			display: none;
		}
	}
}

.gif {
	left: 50%;
	opacity: 0;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%) scale(0);
	transition: 3s ease-out;
	z-index: 999;

	&.loaded {
		opacity: 1;
		transform: translate(-50%, -50%) scale(2);
	}
}

.everyone-wins {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 160px;
	font-weight: 900;
	left: 50%;
	line-height: 1;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%) scale(.5);
	z-index: 999;
	width: 100%;

	&::before {
		animation: spin 1s linear infinite;
		background: #fff;
		content: "";
		border-radius: 100% 10%;
		height: 20vmax;
		filter: blur(40px);
		left: 50%;
		max-width: 800px;
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: 2s;
		width: 50vmax;
	}

	div {
		--row-delay: 0;

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				--row-delay: #{.6 * ($i - 1)};
			}
		}
	}

	span {
		animation: flash .6s infinite;
		display: inline-block;
		transform: scale(0) rotate(0);
		transition: 2s cubic-bezier(.62, 1.69, .63, .91);

		&:empty {
			width: .3em;
		}

		&:nth-child(3n) {
			animation-delay: .2s;
		}

		&:nth-child(3n+1) {
			animation-delay: .4s;
		}

		@for $i from 1 through 20 {
			&:nth-child(#{$i}) {
				transition-delay: calc((.5s * var(--row-delay)) + #{($i - 1) * .2s});
			}
		}
	}

	&.loaded {
		transform: translate(-50%, -50%) scale(1);

		&::before {
			opacity: .5;
		}

		span {
			transform: scale(1) rotate(360deg);
		}
	}
}

@keyframes spin {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

@keyframes flash {
	$color1: red;
	$color2: green;
	$color3: blue;

	0%,
	33% {
		color: $color1;
		text-shadow: .1em .1em 0 rgba($color2, .8), .2em .2em 0 rgba($color3, .6);
	}

	34%,
	66% {
		color: $color2;
		text-shadow: .1em .1em 0 rgba($color3, .8), .2em .2em 0 rgba($color1, .6);
	}

	67%,
	100% {
		color: $color3;
		text-shadow: .1em .1em 0 rgba($color1, .8), .2em .2em 0 rgba($color2, .6);
	}
}

.button {
	background: rgb(4, 211, 66);
	border: 0;
	box-shadow: 0 0 10px rgba(20, 236, 117, .5);
	color: #fff;
	cursor: pointer;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 1px;
	margin-top: 24px;
	padding: 12px 40px;
	text-transform: uppercase;
	transition: box-shadow .2s;

	&:hover {
		box-shadow: 0 0 18px rgba(20, 236, 117, .7);
	}

	&[disabled] {
		opacity: .5;
		pointer-events: none;
	}
}

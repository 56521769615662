.people-list {
	border: 2px solid #d4f;
	box-shadow: 0 0 16px rgba(#d4f, .8);
	margin-left: -8px;
	overflow: hidden;
	position: relative;
	transition: filter .6s;
	width: calc(100% + 16px);

	&.blured {
		filter: blur(4px);
	}
}

.people-list__wrapper {
	background: #121820;
	display: flex;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.people-list__wrapper::before,
.people-list__wrapper::after {
	bottom: 100%;
	box-shadow: 0 0 16px 4px #000;
	content: "";
	height: 50px;
	position: absolute;
	width: 100%;
	z-index: 9;
}

.people-list__wrapper::after {
	top: 100%;
}

.people-list__cursor {
	display: block;
	height: 100%;
	left: 50%;
	position: absolute;
	top: 0;
	transform: scaleX(.4);
	width: 0;
	z-index: 20;

	&::after,
	&::before {
		background: #d4f;
		box-shadow: 0 0 16px #d4f, 0 0 20px 4px rgba(0, 0, 0, .3);
		content: "";
		display: block;
		height: 24px;
		left: 0;
		position: absolute;
		top: 0;
		transform: translate(-50%, -50%) rotate(45deg);
		width: 24px;
		z-index: 1;
	}

	&::after {
		top: 100%;
	}
}

.winner {
	left: calc(50% - 2px);
	position: fixed;
	top: 50%;
	transform: translate(-50%, calc(-50% - 36px));
	transition: .6s;
	z-index: 50;
}

.winner::before {
	background: rgba(0, 0, 0, .3);
	content: "";
	height: 100vh;
	left: 50%;
	opacity: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: inherit;
	width: 100vw;
}

.winner.revealed {
	opacity: 1;
	transform: translate(-50%, -50%) scale(1.3);
}

.winner.revealed::before {
	opacity: 1;
}

.winner .person__image {
	box-shadow: 0 0 0 rgba(0, 0, 0, .6);
	transition: box-shadow .6s;
}

.winner.revealed .person__image {
	box-shadow: 0 4px 8px rgba(0, 0, 0, .6);
}
